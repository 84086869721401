import ApplicationService from "@/services/ApplicationService";

export default {

    namespaced: true,

    state: {
        kaiResult: false
    },
    mutations: {
        setKaiResult(state, kaiResult) {
            state.kaiResult = kaiResult
        },
    },
    actions: {
        searchKAI({commit}, query) {
            commit('setKaiResult', false)
            return new Promise((resolve, reject) => {
                ApplicationService.kaiSearch(query).then(response => {
                    commit('setKaiResult', response.answer)
                    resolve(response)
                })
            })
        }
    },
    getters: {
        kaiResult: state => state.kaiResult
    }
}
