import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store.js'
import RequestStackManager from 'skillbase-component/src/lib/RequestStackManager'

import Modules from '@/applicationSettings/Modules'

import {routes as AdminRoutes} from 'skillbase-component/src/components/admin/routes/vuejs-routes'
import {routes as ModeratorRoutes} from 'skillbase-component/src/components/moderator/routes/vuejs-routes.js'

Vue.use(VueRouter)

let routes = [
    {path: '/login', component: () => import('./views/LoginPage.vue'), meta: {layout: 'onboarding'}},
    {
        path: '/language',
        component: () => import('./views/SelectLanguage.vue'),
        meta: {requiresAuth: true, layout: 'onboarding'}
    },
    {
        path: '/welcome',
        component: () => import('./views/WelcomePage.vue'),
        meta: {requiresAuth: true, layout: 'onboarding'}
    },
    {path: '/step1', component: () => import('./views/Step1.vue'), meta: {requiresAuth: true, layout: 'onboarding'}},
    {path: '/step2', component: () => import('./views/Step2.vue'), meta: {requiresAuth: true, layout: 'onboarding'}},
    {path: '/thanks', component: () => import('./views/Thanks.vue'), meta: {requiresAuth: true, layout: 'onboarding'}},
    {
        path: '/moderator',
        component: () => import('./views/ModeratorPage.vue'),
        meta: {requiresAuth: true, layout: 'moderator', menu: 'moderator'}
    },
    {
        path: '/home',
        component: () => import('./views/HomePage.vue'),
        meta: {requiresAuth: true, menu: "home", layout: 'user'}
    },
    {
        path: '/help',
        component: () => import('./views/Help.vue'),
        meta: {requiresAuth: true, menu: 'help', layout: 'user'}
    },
    { path: '/viewprocess/:oldArticleId', component: () => import("./views/OldArticleRedirect.vue"), meta: { requiresAuth: true, menu: "home", layout: 'user' } },
	{ path: '/rfc/overview/:oldSkillId', component: () => import('./views/OldSkillRedirect.vue'), meta: { requiresAuth: true, menu: 'home', layout: 'user' } },
	{ path: '/products/view/:oldId', component: () => import('./views/OldCopilotRedirect.vue'), meta: { requiresAuth: true, menu: 'home', layout: 'user', type: 'proposition' } }, // proposition
	{ path: '/projects/view/:oldId', component: () => import('./views/OldCopilotRedirect.vue'), meta: { requiresAuth: true, menu: 'home', layout: 'user', type: 'projet' } }, // projet
	{ path: '/request/more/:oldId', component: () => import('./views/OldRequestRedirect.vue'), meta: { requiresAuth: true, menu: 'home', layout: 'user' } },
    {path: '*', redirect: '/login'} // pour pecho toutes les routes pourries
];

// mobile part
routes.push({
    path: '/mobile/pick-create',
    component: () => import('./views/mobile/PickCreateElementsPage.vue'),
    meta: {layout: 'user', requiresAuth: true}
})

Modules.getAllRoutes().forEach(route => {
    routes.push(route)
})

AdminRoutes.forEach(route => {
    routes.push(route)
})

ModeratorRoutes.forEach(route => {
    routes.push(route)
})

export const router = new VueRouter({
    routes: routes,
    mode: 'history',
    base: process.env.BASE_URL
})

let urlToRedirectAfterLogin = false

async function getUserRole() {
    await store.dispatch('acl/updateUserRole')
    let role = {"user": true, "admin": false, "moderator": false}
    if (store.getters['acl/isAdmin']) {
        role['admin'] = true
    }

    if (store.getters['acl/isModerator']) {
        role['moderator'] = true
    }

    return role
}

router.beforeEach(async (to, from, next) => {

    if (to.path != '/' && to.path != '/login' && !store.getters.isLoggedIn) {
        window.localStorage.setItem("historyPath", to.path)
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {

        let userRole = await getUserRole()

        if (to.path !== '/login') {
            RequestStackManager.cancelUnpriorized()
        }

        if (!store.getters.isLoggedIn && from.fullPath !== '/login') {
            urlToRedirectAfterLogin = to.fullPath
            next('/login')
        } else if (urlToRedirectAfterLogin != false && store.getters.isLoggedIn) {
            const url = urlToRedirectAfterLogin
            urlToRedirectAfterLogin = false
            next(url)
        }

        if (store.getters.isLoggedIn) {
            if(to.meta.layout === 'onboarding'){
                next()
            } else if (!((userRole['user'] && to.meta.layout === 'user') || (userRole['moderator'] && to.meta.layout === 'moderator') || (userRole['admin'] && to.meta.layout === 'admin'))) {
                next('/home')
            }
        }

    }
    next()
})